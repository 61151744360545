import AOS from 'aos';
import Swiper from 'swiper';
import Pagination from 'swiper/modules/pagination';
import EffectCoverflow from 'swiper/modules/effect-coverflow';
import Autoplay from 'swiper/modules/autoplay';

function init() {
  AOS.init({ once: true, duration: 500 });

  new Swiper('.swiper', {
    modules: [Pagination, EffectCoverflow, Autoplay],
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    speed: 450,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      enabled: true,
    },
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      1024: {
        allowTouchMove: false,
        effect: 'coverflow',
        slidesPerView: 1.5,
        spaceBetween: 0,
        loop: false,
        centeredSlides: true,
      },
    },
  });

  const menuElement = document.querySelector('.header-menu');
  const burgerButtonElement = document.querySelector('.header-burger');
  const closeMenuButtonElement = document.querySelector('.header-menu-close');
  const scrollToTopElement = document.querySelector('.scroll-to-top');

  burgerButtonElement.addEventListener('click', () => {
    menuElement.classList.add('shown');
    document.body.style.overflow = 'hidden';
  });

  closeMenuButtonElement.addEventListener('click', () => {
    menuElement.classList.remove('shown');
    document.body.style.overflow = 'auto';
  });

  window.onscroll = function () {
    if (document.documentElement.scrollTop > 500) {
      scrollToTopElement.style.opacity = '1';
    } else {
      scrollToTopElement.style.opacity = '0';
    }
  };

  scrollToTopElement.addEventListener('click', function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });
}

init();
